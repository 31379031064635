import { Link } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import TrustSection from "../components/sections/trustSection"
import HeadingGroup from "../components/ui/headingGroup"
import Banner from "../components/ui/banner"
import GetStartedSection from "../components/sections/getStartedSection"
import RequestDemoDrawer from "../components/drawers/requestDemoDrawer";
import SignUpDrawer from "../components/drawers/signUpDrawer";
import PricingDrawer from "../components/drawers/pricingDrawer";
import PageBreak from "../components/ui/pageBreak";
import ImageGroup from "../components/ui/imageGroup";
import styled from "styled-components";
import PrimaryButton from "../components/ui/primaryButton";

const ButtonContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;

const CorporatePage = () => {
  const [openPriceCalculator, setOpenPriceCalculator] = useState(false);
  const [openRequestDemo, setOpenRequestDemo] = useState(false);
  const [openGettingStarted, setOpenGettingStarted] = useState(false);

  const handlePriceCalculatorClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenPriceCalculator(!openPriceCalculator);
      event.preventDefault();
    }
  }

  const handleRequestDemoClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenRequestDemo(!openRequestDemo);
      event.preventDefault();
    }
  }

  const handleGettingStartedClick = (event) => {
    if (window.innerWidth <= 1100) {
      setOpenGettingStarted(!openGettingStarted);
      event.preventDefault();
    }
  }

  return (
    <Layout>
      <SEO title="Corporate Biometric Solutions" />
      <Banner>
        <p>
          Are you looking for an immediate price estimate?&nbsp;<Link to="/pricing" onClick={handlePriceCalculatorClick}>Price Calculator</Link>
        </p>
      </Banner>
      <Hero
        handleRequestDemoClick={handleRequestDemoClick}
        handleGettingStartedClick={handleGettingStartedClick}
        statement="Does your business require"
        punchlines={["Access Control?", "Time and Attendance?", "Remote Site Control?", "Real Time Monitoring?", "Payroll Integration?"]}
        caption="We provide cloud-based Access Control as well as Time and Attendance solutions which rely on fingerprint or facial recognition. Track your employees' attendance against configurable work rules and integrate directly into your payroll provider."
      ></Hero>
      <TrustSection />
      <HeadingGroup heading="Biometric Attendance Tracking for Corporate">
        <p>
          Beyond Attendance fulfills multiple requirements within a single platform for Corporations.
        </p>
        <p>
          The rapid and accurate collection of attendance and leave records eliminates the need for and risks of manual data collection.
        </p>
        <p>
          Our flexible work rules and rosters evenly distribute man hours, optimising wage costs.
        </p>
        <p>
          The Time and Attendance function streamlines and automates payrolls while eliminating potential human error.
        </p>
      </HeadingGroup>
      <PageBreak>Take a look at some of our key features below&nbsp;<span role="img" aria-label="fire">🔥</span></PageBreak>
      
      <ImageGroup reverse={false} image="../images/pricing.svg" heading="Competitive Pricing">
        <p>
          Our product has a scaled pricing model which allows you to choose the Time and Attendance tracking package that best suits your needs and offers maximum value for money.  The system is a month to month contract so you are not locked in by fixed terms and your monthly bill will depend on the number of employees on the system.
        </p>
        <ButtonContainer>
          <PrimaryButton to="/pricing" onClick={handlePriceCalculatorClick} padding="0.8rem" margin="0.8rem 0.8rem 0.8rem 0" fontSize="1rem">Price Calculator</PrimaryButton>
        </ButtonContainer>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/multisite.svg" heading="Multisite Control">
        <p>
          Due to the system being web-based, multiple sites can be controlled from a single centralised location. The devices link up in real-time allowing for immediate data retrieval. This ensures transparency in the workplace and eliminates the need to wait for managers to manually send time sheets and other documentation to their head offices. The instant reporting saves time and allows for quick action to be taken if necessary.
        </p>
      </ImageGroup>

      <ImageGroup reverse={false} image="../images/biometric.svg" heading="Multiple Biometric Options">
        <p>
          You will receive full support for a wide range of biometric devices. Available options include fingerprint and facial recognition, blood vessel mapping and thermal temperature detection. Additional options for smaller teams include USB based units that can simply be linked directly into a POS system through which staff can then effectively be scanned in or out.
        </p>
      </ImageGroup>

      <ImageGroup reverse={true} image="../images/access.svg" heading="Access Control">
        <p>
          Movement within shops or factories to specific areas such as restrooms and change rooms or any other designated area can be tightly controlled and restricted using our biometric system in conjunction with turnstiles and magnetic door locks. Facial and fingerprint recognition reliably and securely denies access to unauthorised personnel or releases the turnstile or magnetic door and allows entry to permitted employees.
        </p>
      </ImageGroup>

      <GetStartedSection
        handleRequestDemoClick={handleRequestDemoClick}
        handleGettingStartedClick={handleGettingStartedClick} />

      <RequestDemoDrawer open={openRequestDemo} handleClose={() => setOpenRequestDemo(false)} />
      <SignUpDrawer open={openGettingStarted} handleClose={() => setOpenGettingStarted(false)} />
      <PricingDrawer open={openPriceCalculator} handleClose={() => setOpenPriceCalculator(false)} />
    </Layout>
  )
}

export default CorporatePage
